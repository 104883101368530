<template>
  <div class="transaction">
    <div>
      <button
        class="botan gradient-border withBG withBlur active asTitle text-uppercase"
      >
        {{ $t('exchange.history') }}
      </button>
      <button
        class="btn-export text-uppercase"
        @click="$bvModal.show('export-transaction')"
      >
        {{ $t('transaction.export') }}
      </button>
    </div>
    <div class="transaction-history">
      <div class="historyTable">
        <div class="table-responsive" v-if="!showTableMobile">
          <b-table :items="History" :fields="fields" show-empty class="myTable">
            <template #empty="">
              <h5 class="text-center p-2">
                {{ $t('support.no_data_available_in_table') }}
              </h5>
            </template>
            <template #cell(Money_MoneyAction)="data">
              {{ Filter(data.value) }}
            </template>
            <template #cell(Money_Currency)>
              <img
                src="~@/assets/images/icons/icon-usd.png"
                alt=""
                class="currency"
              />
            </template>
            <template #cell(Money_USDT)="data">
              <div
                class="text-win"
                :class="{ 'text-lose': Number(data.value) < 0 }"
              >
                {{ TruncateToDecimals2(+data.value, '', 2) }}
              </div>
            </template>
            <template #cell(Money_MoneyStatus)="data">
              <div
                :class="{
                  'text-win':
                    data.item.Money_MoneyAction === 2
                      ? data.item.Money_Confirm === 1
                      : data.item.Money_MoneyStatus === 1,
                  'text-pending':
                    data.item.Money_MoneyAction === 2
                      ? data.item.Money_Confirm === 2
                      : data.item.Money_MoneyStatus === 2,
                  'text-lose':
                    (data.value === data.item.Money_MoneyAction) === 2
                      ? data.item.Money_Confirm === 3
                      : data.item.Money_MoneyStatus === 3,
                }"
              >
                {{
                  data.item.Money_MoneyAction === 2
                    ? StatusFilter(data.item.Money_Confirm)
                    : StatusFilter(data.item.Money_MoneyStatus)
                }}
              </div>
            </template>
            <template #cell(Money_Time)="data">
              {{ getDateTime(data.value * 1000, 'DD/MM/YYYY') }}
            </template>
          </b-table>
        </div>
        <div class="table-mobile" v-else>
          <template v-if="History && History.length > 0">
            <div
              v-for="(history, idx) in History"
              :key="'profitHistory: ' + idx"
              class="w-100"
              role="tablist"
            >
              <b-button
                v-b-toggle="`profitHistory-${idx}`"
                class="btnNoStyle header"
                role="tab"
                >{{ history.Money_Comment }}</b-button
              >
              <b-collapse
                :id="`profitHistory-${idx}`"
                class="mt-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <ul>
                  <li>
                    Id: <span>{{ history.Money_ID }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.address') }}: <span>{{ history.Money_Address }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.comment') }}: <span>{{ history.Money_Comment }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.action') }}: <span>{{ history.Money_MoneyAction }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.currency') }}: <span>{{ history.Money_Currency }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.amount') }}:
                    <span>{{
                      TruncateToDecimals2(+history.Money_USDT, '', 2)
                    }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.rate') }}: <span>{{ history.Money_Rate }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.fee') }}: <span>{{ history.Money_USDTFee }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.time') }}: <span>{{ getDateTime(history.Money_Time * 1000, 'DD/MM/YYYY')   }}</span>
                  </li>
                  <li>
                    {{ $t('wallet.status') }}:
                    <span
                      :class="{
                        'text-win':
                          history.Money_MoneyAction === 2
                            ? history.Money_Confirm === 1
                            : history.Money_MoneyStatus === 1,
                        'text-pending':
                          history.Money_MoneyAction === 2
                            ? history.Money_Confirm === 2
                            : history.Money_MoneyStatus === 2,
                        'text-lose':
                          history.Money_MoneyAction === 2
                            ? history.Money_Confirm === 3
                            : history.Money_MoneyStatus === 3,
                      }"
                    >
                      {{
                        history.Money_MoneyAction === 2
                          ? StatusFilter(history.Money_Confirm)
                          : StatusFilter(history.Money_MoneyStatus)
                      }}
                    </span>
                  </li>
                </ul>
              </b-collapse>
            </div>
          </template>
          <p v-else class="text-center mb-0">
            {{ $t('support.no_data_available_in_table') }}
          </p>
        </div>
        <div class="d-flex w-100 justify-content-end align-items-center">
          <paginate :totalPage="TotalPage" @current="onChangePageHistory" />
        </div>
      </div>
    </div>
    <!-- Modal Export -->
    <b-modal id="export-transaction" hide-footer hide-header>
      <div class="header-modal">
        <h3>{{ $t('transaction.exportTransaction') }}</h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('export-transaction')"
          class="close"
        />
      </div>
      <p class="text-center mb-0 mt-3">
        {{ $t('transaction.contentExportTransaction') }}
        <span class="font-weight-bold text-warning"> ${{ 1 }}</span> ?
      </p>
      <div class="form-group text-center d-flex justify-content-center mt-4">
        <b-button
          class="btnNoStyle btn-export ml-0"
          type="submit"
          @click="onExportTransaction"
        >
          {{ $t('transaction.export') }}
        </b-button>
      </div>
    </b-modal>
    <!-- End Modal Export -->
  </div>
</template>

<script>
// eslint-disable-next-line no-undef
const axios = require('@/store/config/axios.config.js').default;

import Paginate from '@/components/Shared/Pagination.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TransactionViewPage',
  components: {
    Paginate,
  },
  data() {
    return {
      activeTab: 'deposit',
      widthL: 0,
      showTableMobile: false,
    };
  },
  computed: {
    ...mapGetters({
      History: 'history/History',
      Filter: 'history/Filter',
      TotalPage: 'history/HistoryTotalPage',
      UserBalance: 'userinfo/UserBalance',
    }),

    fields() {
      return [
        {
          key: 'Money_ID',
          label: 'ID',
          class: 'text-center',
        },
        {
          key: 'Money_Currency',
          label: this.$t('wallet.currency'),
          class: 'text-center',
        },
        {
          key: 'Money_USDT',
          label: this.$t('wallet.amount'),
          class: 'text-center',
        },
        {
          key: 'Money_Rate',
          label: this.$t('wallet.rate'),
          class: 'text-center',
        },
        {
          key: 'Money_USDTFee',
          label: this.$t('wallet.fee'),
          class: 'text-center',
        },
        {
          key: 'Money_Address',
          label: this.$t('wallet.address'),
          class: 'canWrap text-center',
        },
        {
          key: 'Money_Comment',
          label: this.$t('wallet.comment'),
          class: 'text-center',
        },
        {
          key: 'Money_MoneyAction',
          label: this.$t('wallet.action'),
          class: 'text-center',
        },
        {
          key: 'Money_Time',
          label: this.$t('wallet.time'),
          class: 'text-center',
        },
        {
          key: 'Money_MoneyStatus',
          label: this.$t('wallet.status'),
          class: 'text-center',
        },
      ];
    },
  },
  methods: {
    async onExportTransaction() {
      if (+this.UserBalance.MainBalance < 1) {
        this.$vToastify.error(
          'Your balance is not enough to export',
          'Export Fails!'
        );
        return;
      }
      const data = await axios.get('wallet/historys', {
        params: {
          export: 1,
        },
      });
      // console.log(data.link);
      const link = document.createElement('a');
      link.href = data.link;
      link.target = '_blank';
      link.download = `Transaction-export-${new Date()}.xlsx`;
      link.click();
      this.$store.dispatch('userinfo/req_getBalance');
      this.$bvModal.hide('export-transaction');
    },
    onChangePageHistory(payload) {
      this.$store.dispatch('history/req_getHistory', { page: payload });
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    StatusFilter(id) {
      switch (id) {
        case 1:
          return 'Confirm';
        case 2:
          return 'Pending';
        case 3:
          return 'Canceled';
        default:
          return '';
      }
    },
  },
  created() {
    this.$store.dispatch('history/req_getHistory');
    this.$store.dispatch('history/req_getMoneyActionsFilter');
  },
  async mounted() {
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
  },
};
</script>

<!-- LOCAL STYLE -->
<style lang="scss" scoped>
.btn-export {
  margin-left: 15px;
  letter-spacing: 0.05rem;
  position: relative;
  padding: 10px 30px;
  background: linear-gradient(91.46deg, #ffb70e 1.08%, #fb8739 83%);
  border-radius: 6px;
  color: #fff !important;
  transition: 0.3s ease-in-out;
  z-index: 1;
  border: 0;
  cursor: pointer;
  position: relative;
  font-weight: bold;
  @media (max-width: 576px) {
    padding: 5px 20px;
    font-size: 14px;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(91.46deg, #ffb70e 1.08%, #fb8739 83%);
    filter: blur(21px);
    z-index: -1;
  }
}
.transaction {
  padding: 30px 50px;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  @media (max-width: 576px) {
    padding: 30px 10px;
    padding-bottom: 80px;
  }

  .transaction-tab {
    padding: 5px;
    background: rgba(27, 72, 187, 0.2);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(34px);
    border-radius: 7px;
    width: fit-content;
    margin-bottom: 40px;
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: 576px) {
      width: 100%;
    }

    .listTab {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tab-item {
        padding: 0px 25px;
        height: 55px;

        @media (max-width: 576px) {
          min-width: 130px;
        }

        &.activeTab {
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          border-radius: 6px;
        }
      }
    }
  }
  .botan {
    &.asTitle {
      padding: 10px 30px;
      font-size: 18px;
      font-weight: 700;
      border: 0;
      @media (max-width: 576px) {
        padding: 5px 20px;
        font-size: 14px;
      }
    }
  }

  .transaction-history {
    margin-top: 50px;

    .title {
      font-size: 30px;
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 20px;

      @media (max-width: 576px) {
        font-size: 22px;
      }
    }

    .historyTable {
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      border-radius: 4px;
      padding: 20px;
    }

    .table-mobile {
      width: 100%;
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      border-radius: 4px;
      padding: 20px;

      .header {
        width: 100%;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        border-radius: 6px;
        padding: 0px 25px;
        min-height: 55px;
        margin-bottom: 20px;
      }

      .collapse {
        margin-top: -20px !important;
        padding: 20px 10px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        -webkit-backdrop-filter: blur(34px);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        margin-bottom: 20px;

        P {
          margin-bottom: 0px;
        }
        ul {
          li {
            font-weight: 700;
            span {
              word-wrap: break-word;
              font-weight: 400;
              color: rgb(219, 224, 235);
            }
          }
        }
      }
    }
  }
}
</style>
